body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.ji-kio{
	color: #ff7bac !important;
}
.ji-tshenn{
	color: #7ac943 !important;
}
.ji-na{
	color: #52bce4 !important;
}
.ji-kam{
	color: #ff931e !important;
}
.ji-phu{
	color: #202020 !important;
}
.ji-ng{
  color: #fefe9a !important;
}
