.App-tsoo {
  margin-top: 5rem;
}

.App-tsiatbok {
  display: inline-block;    
  overflow: hidden;
}

@media screen and (min-width: 1281px) {
  .App-tsiatbok {
    width: 500px;
    height: 500px;
  }  
  .App-tsoo {
    margin-top: 10rem;
  }
}


@media (min-width: 992px) and (max-width: 1280px) {
  .App-tsiatbok {
    width: 400px;
    height: 400px;
  }  
}


@media (min-width: 768px) and (max-width: 991px) {
  .App-tsiatbok {
    width: 300px;
    height: 300px;
  }  
}


@media (max-width: 767px) {
  .App-tsiatbok {
    width: 40vw;
    height: 40vw;
  }  
}

.App-tsiatbok img {
  /*transition: transform .5s ease;*/
  /*transform-origin: 0 0;*/
  transition: transform .25s, visibility .25s ease-in, opacity .25s;
}

.App-tsiatbok:hover img {
  transform: scale(1.05);
  opacity: 0.9;
}

a.ui.tsiatbok.image, 
a.ui.tsiatbok.image img {
  width: 100%;
  height: 100%;
}
