.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  height: 65vmin;
  animation: bumping linear 0.7s infinite;
}

@keyframes bumping{
    0%{
        transform: scale(1);
        
    }
    45%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.05);
    }
    55%{
        transform: scale(1);
    }
    100%{
        transform: scale(1);
    }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

aside {
  color: #fff;
  margin-top: 5rem;
}

@media screen and (max-width: 768px) {
  .App-header {
    min-height: 80vh;
  }
}

.ui.massive.header {
  font-size: 7vmin;
  color: #ffffff !important;
  text-shadow: 1px 1px 10px #777;
}

.ui.medium.header {
  margin-top: -5px;
}

.App-link {
  color: #09d3ac;
}


/*Bé-phiò liú-á*/
.App-buy {
  padding-top: 10vh;
}
.ui.massive.inverted.button {
  color: #eaeaea !important;
  box-shadow: 0 0 2px 3px #52bce4,
              0 0 2px .2px #52bce4 inset !important;
}

.ui.massive.inverted.button:hover {
  color: #ffffff !important;
  box-shadow: 0 0 3px 4px #52bce4,
              0 0 2px .2px #52bce4 inset !important;
  background-color: #282c34 !important;
}

.fb-icon {
  width: 15px;
  height: 17px;
  vertical-align: text-top;
}

footer {
  border-top: 1px #686b70 solid;
  margin-top: 20vh;
  padding-top: 20px;
  padding-bottom: 20px;
}

footer > a > img {
  vertical-align: center;
  margin-right: 10px;
}